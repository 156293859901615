import {
	getGbraidClickId,
	getGclid,
	getWbraidClickId,
} from '$lib/Components/Pixels/googleAds';
import { getQuoraClickId } from '$lib/Components/Pixels/quora';
import { getRedditClickId } from '$lib/Components/Pixels/reddit';

import { PUBLIC_ENV } from '$env/static/public';

const BROWSER = typeof window !== 'undefined';

/**
 * Handles form submission, setting relevant tracking identifiers and other necessary parameters.
 * @param {FormData} formData - The form data to be submitted.
 * @param {URL} url - The current page URL.
 */
export function handleFormSubmit(formData, url) {
	formData.set('url', url?.toString());

	const gclid = getGclid(url);
	const wbraid = getWbraidClickId(url);
	const gbraid = getGbraidClickId(url);
	const redditClickId = getRedditClickId(url);
	const quoraClickId = getQuoraClickId(url);

	// google
	if (gclid) {
		formData.set('gclid', gclid);
	}
	if (wbraid) {
		formData.set('wbraid', wbraid);
	}
	if (gbraid) {
		formData.set('gbraid', gbraid);
	}

	// reddit
	if (redditClickId) {
		formData.set('rdtCid', redditClickId);
	}

	// quora
	if (quoraClickId) {
		formData.set('qclid', quoraClickId);
	}
}

export function sendPixelConversions({
	id,
	email,
	firstName,
	lastName,
	postalCode,
	phone,
	country,
}) {
	if (PUBLIC_ENV === 'production') {
		// google
		let googleUser = {};
		if (email) {
			googleUser.email = email;
		}
		if (phone) {
			googleUser.phone_number = phone;
		}
		if (firstName || lastName || postalCode || country) {
			googleUser.address = {};
			if (firstName) {
				googleUser.address.first_name = firstName;
			}
			if (lastName) {
				googleUser.address.last_name = lastName;
			}
			if (postalCode) {
				googleUser.address.postal_code = postalCode;
			}
			if (country) {
				googleUser.address.country = country;
			}
		}
		gtag('set', 'user_data', googleUser);
		window.dataLayer = window.dataLayer || [];
		gtag('event', 'conversion', {
			send_to: 'AW-991299045/HSwmCMrBnqwZEOWL2NgD',
			transaction_id: id,
		});

		// quora
		if (typeof window.qp !== 'undefined') {
			window.qp('init', '7c8c003a0a6546e3aeae3b86ca97d53a', {
				email,
			});
			window.qp('track', 'GenerateLead', {
				event_id: id,
			});
		}

		// reddit
		if (typeof window.rdt !== 'undefined') {
			window.rdt('init', 't2_znjfi', { email });
			window.rdt('track', 'Lead', {
				conversionId: id,
			});
		}

		// facebook
		if (typeof window.fbq !== 'undefined') {
			window.fbq('init', '516648621844325', {
				em: email,
				fn: firstName,
				ln: lastName,
				ph: phone,
				zp: postalCode,
				country: country,
			});

			window.fbq('track', 'Lead', {}, { eventID: id });
		}

		// piwik pro
		window._paq = window._paq || [];
		window._paq.push(['trackGoal', '38f8cbf6-6aa6-4a88-8b4e-105ce1630c8f']);

		// bing
		window.uetq = window.uetq || [];
		// Format phone numbers according to the E.164 standard
		// For email:
		// Remove all whitespaces and accents(ex: à) from the email address
		// Remove everything between “+” and “@” (e.g.name + withplus@outlook.com becomes name @outlook.com)
		// Remove any periods that come before “@” and make sure it doesn’t end with a period(e.g.ex.ample@outlook.com.becomes example@outlook.com)
		// Make sure the text is in lower case and that the email address contains “@” sign
		let [subject, domain] = email.split('@');
		subject = subject?.replace(/\+.*/, '').replace(/\./g, '').trim();
		domain = domain?.replace(/\.+$/, '').trim();
		const bingEmail = `${subject}@${domain}`.toLowerCase();
		window.uetq.push('set', {
			pid: {
				em: bingEmail,
				ph: phone,
			},
		});
		window.uetq.push('event', 'submit_lead_form', {});
	}
}

function gtag() {
	window.dataLayer.push(arguments);
}

export function getClickId(url, param) {
	if (!BROWSER || !url) {
		return null;
	}
	let clickId = url?.searchParams?.get(param);
	if (clickId) {
		try {
			localStorage.setItem(param, clickId);
		} catch (e) {
			console.error(e);
		}
		return clickId;
	}
	try {
		clickId = localStorage.getItem(param);
	} catch (e) {
		console.error(e);
	}
	return clickId;
}
